<template>
    <div class="container">
        <div class="row">
            <div class="col-12 py-4">
                <div class="p-fluid countainer-fluid">
                    <Button 
                        class="p-button-raised p-button-rounded my-3 p-button-danger" 
                        icon="pi pi-arrow-left"
                        @click="goBack" />
                    <h3>ADD PROGRAMME</h3> 
                    <Divider align="left">
                        <div class="inline-flex align-items-center">
                            <span class="p-tag">Information</span>
                        </div>
                    </Divider>

                    <!-- Name, Status -->
                    <div style="display: flex; width: 100%; ">
                        <div style="width: 75%; " >
                           <div
                            style="
                                display: flex;
                                flex-direction: column;
                                margin-left: 20px;
                                margin-bottom: 20px;
                                margin-top: 10px;
                            ">
                                <label> Programme </label>
                                <InputText  
                                    id="name"
                                    v-model.trim="newProgramForm.name"
                                    @blur="v$.newProgramForm.name.$touch"
                                    required="true"
                                    autofocus=""
                                    :class="{'p-invalid': !newProgramForm.name}"
                                />
                                <small class="p-error" v-if="!newProgramForm.name">
                                    Programme Name is required
                                </small>
                           </div>
                        </div>
                        <div style="width: 25%; ">
                            <div
                                style="
                                    margin-left: 20px;
                                    display: flex;
                                    gap: 2%;
                                    margin-top: 1.5%;
                                    margin-left: 20px;
                                    margin-bottom: 1rem;
                                "
                            >
                                <div style="width: 100%;">
                                    <div class="CTU">
                                        <label>Analyst Status - Internal Use only</label>
                                        <Dropdown 
                                            v-model="newProgramForm.documentProgramStatusId"
                                            :options="programStatus"
                                            optionLabel="status"
                                            optionValue="documentProgramStatusId"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Topic -->
                    <div style="width: 100%; ">
                        <div style="
                                display: flex;
                                align-items: center;
                                gap: 1rem;
                                margin-left: 20px;
                            ">
                            <label for="programName">Topic</label>

                            <div
                                style="width: 100%"
                                v-if="newProgramForm.programTopic"
                            >
                                <label for="topicId"><strong>{{ filterTopicName(newProgramForm.programTopic) }}</strong></label>
                                
                            </div>
                            <div v-else>
                                <div style="width: 100%; height: 100%;">
                                    <ProgressSpinner style="width: 3rem; height: 3rem;"/>
                                </div>
                            </div>
                            
                            <!-- <Dropdown 
                                v-model="newProgramForm.programTopic" 
                                :options="topics" 
                                optionLabel="name"
                                optionValue="topicId" 
                                placeholder="Select topic" 
                                style="width: 100%" /> -->
                            <!-- <small class="p-error" v-if="!newProgramForm.programTopic">
                                Topic is required
                            </small> -->
                        </div>
                    </div>
                    <!-- segmentations -->
                    <div
                        style="
                            display: flex;
                            align-items: center;
                            gap:1rem;
                            margin-left: 20px;
                            margin-top: 20px;
                        "
                    >
                        <label>Segments</label>
                        <div style="display: flex; gap: 1rem;">
                            <div v-for="(segmentation, index) in segmentations" :key="segmentation.segmentationId">
                                <div v-if="!segmentation.isRegional" style="min-width: 10rem;">
                                    <template v-if="newProgramForm.programTopic">
                                        <Divider align="left">
                                            <span class="p-tag">{{segmentation.name}}</span>
                                        </Divider>
                                        <Dropdown 
                                            v-model="newProgramForm.segmentationItems[index]"
                                            :options="segmentation.segmentationItems"
                                            option-label="name"
                                            option-value="segmentationItemId"
                                            placeholder="Select SegmentationItems"
                                            style="width: 70%" 
                                        />
                                    </template>
                                    <template v-else>
                                        <small class="p-error">
                                            Topic is not selected
                                        </small>
                                    </template>
                                </div>
                                <div v-else style="min-width: 10rem;">
                                    <template v-if="newProgramForm.programTopic">
                                        <Divider align="left">
                                            <span class="p-tag">{{ segmentation.name }}</span>
                                        </Divider>

                                        <Dropdown 
                                            v-model="newProgramForm.country[index]" 
                                            :options="getCountriesFromSegm(index)" 
                                            optionLabel="name"
                                            optionValue="countryId" 
                                            :filter="true" 
                                            placeholder="Select Country"
                                            style="width: 70%" 
                                            @change="setSegmItem(index ,$event )"/>
                                    </template>

                                    <template v-else>
                                        <small class="p-error">
                                            Topic is not selected
                                        </small>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Header Information -->
                    <div style="
                        margin-left: 20px;
                        display: flex;
                        gap: 2%;
                        margin-top: 1.5%;
                        margin-left: 20px;
                        margin-bottom: 1.4rem;
                    ">
                        <div style="width: 25%;">
                            <div class="PMC">
                                <label> Product</label>
                                <InputText v-model.trim="newProgramForm.product"/>
                            </div>
                            <div class="PMC">
                                <label> Manufacturer</label>
                                <!-- <InputText v-model.trim="newProgramForm.manufacturer"/> -->
                                <Dropdown 
                                    v-model="newProgramForm.manufacturerId"
                                    :options="companies"
                                    option-label="name"
                                    option-value="companyId"
                                    :filter="true"
                                    placeholder="SelectManufacturer"
                                    :showClear="true"
                                />
                            </div>
                            <div class="PMC">
                                <label>Country Manufacturer</label>
                                <!-- <InputText v-model.trim="newProgramForm.countryManufactured" /> -->
                                <Dropdown 
                                    v-model="newProgramForm.countryManufactured"
                                    :options="countries"
                                    optionLabel="name"
                                    option-value="name"
                                    :filter="true"
                                    placeholder="Select Country Manufacturer"
                                    :showClear="true"
                                />
                            </div>
                        </div>

                        <div style="width: 25%;">
                            <div class="CTU">
                                <label>Contract Year(s)</label>
                                <!-- <InputText v-model.trim="newProgramForm.contractYear" /> -->
                                <div class="CTU-contractYear">
                                    <div 
                                        style="
                                            position: relative; 
                                            width: 50%; 
                                            display: flex; 
                                            justify-content: center; 
                                            align-items: center;"
                                    >
                                        <Calendar 
                                            v-model="newProgramForm.programStartYear" 
                                            view="year" 
                                            dateFormat="yy" 
                                            class="CTU-contractYear-input" 
                                        />
                                        <i class="pi pi-angle-down CTU-contractYear-input-icon" style="font-size: 1rem"></i>
                                    </div>
                                    <div 
                                        style="
                                            position: relative; 
                                            width: 50%; 
                                            display: flex; 
                                            justify-content: center; 
                                            align-items: center;"
                                        >
                                        <Calendar 
                                            v-model="newProgramForm.programEndYear" 
                                            view="year" 
                                            dateFormat="yy" 
                                            class="CTU-contractYear-input" 
                                        />
                                        <i class="pi pi-angle-down CTU-contractYear-input-icon" style="font-size: 1rem"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="CTU">
                                <label>Total Value</label>
                                <InputText v-model.trim="newProgramForm.contractValue" />
                            </div>

                            <div class="CTU">
                                <label>Unit Price</label>
                                <InputText v-model.trim="newProgramForm.unitPrice" />
                            </div>
                        </div>

                        <div style="width: 25%;">
                            <div class="TqD">
                                <label>Total Quantity</label>
                                <InputText v-model.trim="newProgramForm.totalQuantity" />
                            </div>
                            <div class="TqD">
                                <label>Delivery Year</label>
                                <InputText v-model.trim="newProgramForm.deliveryYear" />
                            </div>
                            <div class="TqD">
                                <label>Programme Date</label>
                                <Calendar 
                                    v-model="newProgramForm.programDate" 
                                    dateFormat="dd/mm/yy" 
                                    disabled=""
                                />
                            </div>
                        </div>

                        <div style="width: 25%;">
                        </div>
                    </div>

                    <!-- Program Summary, analyst Notes -->
                    <div style="
                        margin-left: 20px;
                        display: flex;
                        gap: 2%;
                        margin-top: 1.5%;
                        margin-left: 20px;
                        margin-bottom: 1.4rem;
                    ">
                        <div style="width: 50%;">
                            <div 
                                style="display: flex; flex-direction: column;"
                            > 
                            <label style="margin-bottom: 1rem;">Programme Summary for "Programmes per Country Overview"</label>
                            
                            <!-- <QuillEditor 
                                theme="snow" 
                                v-model:content="newProgramForm.programSummary" 
                                :toolbar="quillToolbar"
                                contentType="html"
                                :style="{ 'min-height': '200px' }" 
                            /> -->

                            <QuillEditor 
                                theme="snow" 
                                v-model:content="newProgramForm.programSummary" 
                                :toolbar="quillToolbar"
                                contentType="html"
                                :style="{ 'min-height': '120px',}" 
                            />
                            <!-- :style="{ 'min-height': '120px', 'height': 'auto', 'max-height': '200px' }"  -->
                            

                            <!-- <Textarea 
                                v-model.trim="newProgramForm.programSummary" 
                                rows="5"
                                cols="30" 
                            /> -->
                            </div>
                        </div>

                        <div style="width: 50%;">
                            <div 
                                style="display: flex; flex-direction: column; gap: 1rem;"
                            > 
                            <label>Analyst Notes</label>
                            <!-- <Textarea 
                                v-model.trim="newProgramForm.analystNotes" 
                                rows="5"
                                cols="30" 
                            /> -->
                            <Textarea 
                                v-model.trim="newProgramForm.analystNotes" 
                                rows="8" 
                                cols="30" 
                            />
                            <!-- <Textarea 
                                v-model.trim="newProgramForm.analystNotes" 
                                rows="13" 
                                cols="30" 
                            /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="createProgramError" class="row">
            <div class="col-12 py-4"> 
                <div class="p-fluid countainer-fluid">
                    <h5 style="color: red;">- {{ createProgramErrorMessage  }} </h5>
                </div>
            </div>  
        </div>

        <div class="row">
            <div class="col-12 p-4">
                <div class="d-flex justify-content-end px-3" style="gap: 1rem;">
                    <Button 
                        class="p-button"
                        :loading="createProgramIsLoading" 
                        :disabled="
                            this.v$.newProgramForm.$invalid || createProgramIsLoading
                        " 
                        @click="createProgramPage()" 
                    >
                    
                        <label style="margin-right: 0.5rem;">Create Programme</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk" />
                    </Button>
                    
                    <Button 
                        label="Save" 
                        icon="pi pi-check" 
                        class="p-button-success ml-3"
                        :loading="createProgramIsLoading" 
                        :disabled="this.v$.newProgramForm.$invalid || createProgramIsLoading" 
                        @click="createProgramPageGoBack()" 
                    >
                        <label style="margin-right: 0.5rem;">Create Programme and Go Back</label>
                        <font-awesome-icon icon="fa-regular fa-floppy-disk-circle-arrow-right" />
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import { QuillEditor } from '@vueup/vue-quill';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
import { createProgram as createProgramService } from '../../../../program/services/program.service';

const toast = useToast();

export default {
    name: 'NewProgram',
    components: {
        Button,
        Divider,
        InputText,
        Calendar,
        Dropdown,
        Textarea,
        ProgressSpinner,
        QuillEditor,
    },
    // props:['isEditProgram'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: true,
            createProgramIsLoading: false,
            newProgramForm: {
                name: null,
                programTopic: null, 
                segmentationItems: [],
                product: null,
                // Replace manufacturer to manufacturerId
                // manufacturer:null,
                manufacturerId: null,
                countryManufactured: null, 
                //we Are not using contractYear
                contractYear: null, 
                programStartYear: null, 
                programEndYear: null, 

                contractValue: null,
                unitPrice: null,
                totalQuantity: null,
                deliveryYear:null,
                programDate: null,
                documentProgramStatusId: null,
                country: [],
                region:[],
                programSummary: null,
                analystNotes: null,
            },
            // redirectToProgram
            redirectDocumentProgramTopicId: null, 

            createProgramError: false,
            createProgramErrorMessage: '',

            quillToolbar: [
                [{ 'header': [4,5, false] }],  // Adding heading
                ['bold', 'italic', 'underline', ], // textFormat
                [{ 'list': 'ordered' }, { 'list': 'bullet' }], // lists
            ],
        }
    },
    validations() {
        return {
            newProgramForm: {
                name: { required },
                programTopic: {required}, 
            }
        }
    },
   
    async mounted() {
        await this.getTopics();
        await this.getProgramStatus(); 
        await this.getCompanies();
        await this.getCountries(); 
        if(this.selectedTopic){
            this.newProgramForm.programTopic = this.selectedTopic.topicId
            this.newProgramForm.programDate = new Date();
        }
        

        if(this.companyIdForCreateProgram){
            console.log(this.companyIdForCreateProgram);
            this.newProgramForm.manufacturerId = this.companyIdForCreateProgram
        }

        // Start With program Status New
        if(this.programStatus.length > 0 && this.programStatus.find(programStat => programStat.status === "New")){
            let programStatusNew = this.programStatus.find(programStat => programStat.status === "New");
            this.newProgramForm.documentProgramStatusId = programStatusNew.documentProgramStatusId
        }

    },
    beforeUnmount(){ // CLEAN COMPANYIFORCREATEPROGRAM
        this.$store.dispatch('resetUpdateCompanyForCreateProgram'); 
    },
    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        companyIdForCreateProgram(){
            return this.$store.getters.obtainCompanyForCreateProgram;
        },
        topics() {
            return this.$store.getters.obtainTopics;
        },
        segmentations(){
            return this.$store.getters.obtainSegmentations;
        },
        programStatus(){
            return this.$store.getters.obtainProgramStatus;
        },
        companies(){
            let companiesOrder = this.$store.getters.obtainCompanies; 
            companiesOrder.sort(function(a,b){
                if(a.name.toLowerCase() < b.name.toLowerCase()){ return -1}
                if(a.name.toLowerCase() > b.name.toLowerCase()){return 1;}
                return 0;
            });
            return companiesOrder
        },
        countries(){
            const x  = this.$store.getters.obtainCountries; 
            return x.sort((a,b) => a.name.localeCompare(b.name))
        },
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        filterTopicName(topicId){
            const a = this.topics.find(topic=> topic.topicId === topicId).name
            return a 
        },

        async getTopics() {
            try {
                this.loading = true;
                await this.$store.dispatch('obtainTopics');
                this.loading = false;
            } catch (error) {
                toast.error(error.message);
                this.loading = false
            }
        },
        async getProgramStatus(){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainProgramStatus');
                this.loading = false; 
            } catch (error) {
                this.loading = false;
            }

        },
        async getCompanies(){
            try {
                this.loading = true;
                await this.$store.dispatch('obtainCompanies');
                this.loading = false;
            } catch (error) {
                this.loading = false
            }
        },
        async getCountries(){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainCountries');
                this.loading = false; 
            } catch (error) {
                this.loading = false;
            }
        },
        async getSegmentations(topicId){
            try {
                this.loading = true; 
                await this.$store.dispatch('obtainSegmentations', topicId)
                this.loading= false; 
            } catch (error) {
                this.loading = false; 
            }
        },
        getCountriesFromSegm(index){
            let countriesList = []
            this.segmentations.filter(se => se.isRegional)[index].segmentationItems.map(si => si.region.regionCountries.map(rc => countriesList.push( rc.country )) )
            const x = countriesList.flat().sort( (a,b) => a.name.localeCompare(b.name) )
            return x
        },
        setSegmItem(index ,event){
            const segmentationItemId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.countryId === event.value )).map(filtered => filtered.segmentationItemId)
            const regionId = this.segmentations.filter(se => se.isRegional)[index].segmentationItems.filter(si => si.region.regionCountries.some(c => c.countryId === event.value )).map(filtered => filtered.region.regionId)
            this.newProgramForm.region[index] = regionId[0]
            this.newProgramForm.segmentationItems[index] = segmentationItemId[0]

        },

        async createProgramPageGoBack(){
            await this.createProgram();
            if(!this.createProgramError){
                this.goBack();
            }
        },

        async createProgramPage(){
            await this.createProgram();
            if(!this.createProgramError){
                // this.$router.push(`./overview/${this.redirectDocumentProgramTopicId}`);
                this.$router.push({
                    path: `./overview/${this.redirectDocumentProgramTopicId}`,
                    query: { fromCreated: true } // for goback -2
                });
            }
        },

        async createProgram(){
            try {
                this.createProgramError= false
                this.createProgramErrorMessage = ''
                if(!this.v$.newProgramForm.$invalid){
                    this.createProgramIsLoading = true;

                    let programForm = {
                        'name' : this.newProgramForm.name,
                        'documentProgramTopics': []
                    }

                    let documentProgramTopics ={
                        'analystNotes': this.newProgramForm.analystNotes,
                        'topicId' : this.newProgramForm.programTopic,
                        'programDate':this.newProgramForm.programDate,
                        'programSummary': this.newProgramForm.programSummary,
                        'Product': this.newProgramForm.product,
                        //Replace manufacturer to manufacturerId
                        // 'manufacturer': this.newProgramForm.manufacturer,
                        'manufacturerId': this.newProgramForm.manufacturerId,
                        'CountryManufactured': this.newProgramForm.countryManufactured,
                        //we are not using contractYear
                        // add programStarYear & programEndYear
                        'ContractYear':this.newProgramForm.contractYear,
                        'programStartYear': (this.newProgramForm.programStartYear)?this.newProgramForm.programStartYear.getFullYear() : null ,
                        'programEndYear':  (this.newProgramForm.programEndYear) ? this.newProgramForm.programEndYear.getFullYear() : null , 
                        
                        'ContractValue': this.newProgramForm.contractValue,
                        'TotalQuantity': this.newProgramForm.totalQuantity,
                        'deliveryYear': this.newProgramForm.deliveryYear,
                        'UnitPrice': this.newProgramForm.unitPrice,
                        // 'segmentationItems':[]
                        'docProgramTopicSegItems':[],
                        'documentProgramStatusId': this.newProgramForm.documentProgramStatusId,
                    }

                    let segmentationItems = []
                    this.newProgramForm.segmentationItems.map((item, index)=>{
                            const regionCountry ={
                                'regionId':this.newProgramForm.region[index],
                                'countryId':this.newProgramForm.country[index],
                            }
                            if(!this.newProgramForm.country[index]){
                                const segmentationItems1 = {
                                    'segmentationItemId': item,
                                    'regionCountry': null,
                                }
                                segmentationItems[index]= segmentationItems1
                            }
                            else{
                                const segmentationItems1 = {
                                    'segmentationItemId': item,
                                    'regionCountry': regionCountry,
                                }
                                segmentationItems[index]= segmentationItems1
                            }
                        }
                    );
                    documentProgramTopics.docProgramTopicSegItems= segmentationItems
                    programForm.documentProgramTopics.push(documentProgramTopics)
                    // console.log(JSON.stringify(programForm) )
                    // await this.$store.dispatch('addNewProgram', programForm );
                    const response = await createProgramService(programForm);
                    this.redirectDocumentProgramTopicId = response.data.documentProgramTopics[0].documentProgramTopicId
                    this.createProgramIsLoading = false;
                    toast.success('Successfully created.');

                }
                else{
                    toast.error('Fill Required Data');
                    this.createProgramError= true;
                    this.createProgramErrorMessage= 'Fill Required Data';
                }
            } catch (error) {
                this.createProgramError=true
                this.createProgramErrorMessage=''
                this.createProgramIsLoading = false;
                toast.error(error.message);
            }
        }
    },
    watch: {
        'newProgramForm.programTopic': {
            async handler(newVal) {
                await this.getSegmentations(newVal)
            },
            deep: true,
        },
        'selectedTopic':{
            async handler(newVal) {
                this.newProgramForm.programTopic = newVal.topicId
                this.newProgramForm.programDate = new Date();
            },
            deep: true,
        }
    }
}

</script>
<style>

/* some stylesClasses are on ProgramOverViewPage.vue Scoped Globaly */
.p-datepicker-month {
    background: #673AB7;
    color: white !important;
    /* text-decoration: underline; */
}
.p-datepicker-month:hover{
    background: blue;
}
.p-datepicker-year {
    background: #673AB7;
    color: white !important;
    /* text-decoration: underline; */
}
.p-datepicker-year:hover {
    background: blue;
}

.CTU-contractYear{
    display: flex;
    gap: 1rem;

}

.CTU-contractYear-input{
    /* min-width: 1rem; */
    max-width: 100%;
    position: relative;
}

.CTU-contractYear-input-icon{
    position: absolute;
    right: 2.5%;
}

</style>